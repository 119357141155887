import './style.css';
import 'ol/ol.css';
import {Map, View} from 'ol';
import {OSM,Vector as VectorSource} from 'ol/source';
import Extent from 'ol/interaction/Extent';
import * as olExtent from 'ol/extent';
import Circle from 'ol/geom/Circle';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import Overlay from 'ol/Overlay';
import {fromLonLat} from 'ol/proj';
import {toStringHDMS} from 'ol/coordinate';
import {transform} from 'ol/proj';
import TileJSON from 'ol/source/TileJSON';
import XYZ from 'ol/source/XYZ';

import $ from "jquery";

import apply from 'ol-mapbox-style';

let map;

$(function(){

  const hiDPI = (window.devicePixelRatio == 1) ? "" : "@2x";
  const dpiFactor = (window.devicePixelRatio == 1) ? 1 : 2;

  const traceLineStyleFunction = function(feature){
    return new Style({
      stroke: new Stroke({
        color: 'rgba(255,255,255,0.4)',
        width: 1.0,
      })
    })
  };

  const styleJson = "https://api.maptiler.com/maps/476c6264-01e5-46fc-80dc-85ec847f31c4/style.json?key=cLbgnEroendMnOR6Jxaq";

  const container = document.getElementById('popup');
  const content = document.getElementById('popup-content');
  const closer = document.getElementById('popup-closer');

  const overlay = new Overlay({
    element: container,
    autoPan: {
      animation: {
        duration: 100,
      },
    },
  });

  $.ajax({
    url: "./mo_covid19_14_days.geojson",
    dataType: "json",
    success: function(result){

      const locations = new GeoJSON({
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(result);

      var vectorSource = new VectorSource({
        features: locations
      });

      var vectorLayer = new VectorLayer({
        className: "locations",
        source: vectorSource,
        style: styleFunction,
        zIndex: 99,
        properties: {
          id: "trace_nodes"
        }
      });

      map.addLayer(vectorLayer);
    }
  });

  $.ajax({
    url: "./case_tracks.geojson",
    dataType: "json",
    success: function(result){

      const locations = new GeoJSON({
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(result);

      const vectorSource = new VectorSource({
        features: locations
      });

      const vectorLayer = new VectorLayer({
        className: "locations",
        source: vectorSource,
        style: traceLineStyleFunction,
        zIndex: 90,
        properties: {
          id: "trace_links"
        }
      });

      map.addLayer(vectorLayer);
    }
  });

  $(".checkbox").change(function(){

    const layers = map.getLayers();

    const checkBoxID = $(this).attr("id");

    const isChecked = $(this).prop("checked");

    layers.forEach(function(layer){

      if (checkBoxID && (checkBoxID == layer.get("id"))) {
        layer.setVisible(isChecked);
      }

    });

  });

  $("#accept").click(function(){

    $("#disclaimer").animate({bottom: "100%"});

  });

  $("#decline").click(function(){

    window.close();

  });

  var north = 22.3;
  var west = 113.4;
  var south = 22;
  var east =  113.7;

  var newlonLat = transform([west, north], "EPSG:4326", "EPSG:3857");
  var west_3857 = newlonLat[0];
  var north_3857 = newlonLat[1];

  newlonLat = transform([east, south], "EPSG:4326", "EPSG:3857");
  var east_3857 = newlonLat[0];
  var south_3857 = newlonLat[1];

  const view = new View({
      projection: 'EPSG:3857',
      // constrainResolution: true,
      // center: [0,0],
      center: fromLonLat([113.55, 22.17]),
      zoom: 13,
      extent: [west_3857, south_3857, east_3857, north_3857],
      enableRotation: false
  });

  const styleFunction = function (feature) {

    const lastVisitedStr = feature.getProperties()["last_visited"];

    console.log(feature)

    if (lastVisitedStr) {

      var timeDiff = new Date() - new Date(lastVisitedStr.replace(" ","T"));

    } else {
      var timeDiff = 604800000
    }

    let cases = [];

    feature.getProperties()["cases"].forEach(function(element){
      cases.push(element["case"]);
    });

    const uniqueCases = cases.filter((v, i, a) => a.indexOf(v) === i);

    timeDiff = 0

    const pointColor = (timeDiff < 604800000) ? 'rgba(208,117,236,0.8)' : 'rgba(208,117,236,0.4)';

    const image = new CircleStyle({
      radius: 20*uniqueCases.length/18,
      fill: new Fill({
        color: pointColor,
      }),
      stroke: new Stroke({color: pointColor, width: 1}),
    });

    const style = new Style({
      image: image,
    });

    return style;
  };

  // const styleFunctionLineString = function (feature) {

  //   var lastVisitedStr = feature.getProperties()["case_id"];

  //   console.log()

  //   var timeDiff = new Date() - new Date(lastVisitedStr.replace(" ","T"));

  //   var pointColor = (timeDiff < 604800000) ? 'rgba(208,117,236,0.8)' : 'rgba(208,117,236,0.4)';

  //   var image = new CircleStyle({
  //     radius: 8,
  //     fill: new Fill({
  //       color: pointColor,
  //     }),
  //     stroke: new Stroke({color: pointColor, width: 1}),
  //   });

  //   var style = new Style({
  //     image: image,
  //   })

  //   return style;
  // };

  const geolocation = new Geolocation({
    // enableHighAccuracy must be set to true to have the heading value.
    trackingOptions: {
      enableHighAccuracy: true,
    },
    projection: view.getProjection(),
  });

  function el(id) {
    return document.getElementById(id);
  }

  const accuracyFeature = new Feature();
  
  // geolocation.on('change:accuracyGeometry', function () {
  //   // accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
  // });

  const positionFeature = new Feature();

  positionFeature.setStyle(
    new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2,
        }),
      }),
    })
  );


  geolocation.on('change:position', function () {
    const coordinates = geolocation.getPosition();
    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
  });

  const mapSource = new TileJSON({
        url: 'https://api.maptiler.com/maps/476c6264-01e5-46fc-80dc-85ec847f31c4/tiles.json?key=cLbgnEroendMnOR6Jxaq',
        tileSize: 512,
        crossOrigin: 'anonymous'
      });


  const mapSource_XYZ = new XYZ({
      // url: "https://api.maptiler.com/maps/4c530e84-eb97-4d87-b4d5-f12b1c9bd881/{z}/{x}/{y}"+hiDPI+".png?key=LjuD4ZfhGkhda1bclITx"
      url: "https://api.maptiler.com/maps/4c530e84-eb97-4d87-b4d5-f12b1c9bd881/256/{z}/{x}/{y}"+hiDPI+".png?key=cLbgnEroendMnOR6Jxaq",
      tilePixelRatio: dpiFactor,
      cacheSize: 512
  })

  const mapLayer = new TileLayer({
      source: mapSource,
      tileSize: 512,
      tilePixelRatio: window.devicePixelRatio
  });

  const mapLayer_XYZ = new TileLayer({
      source: mapSource_XYZ,
      tileSize: 256

  });

  map = new Map({
    target: 'map',
    pixelRatio: dpiFactor,
    overlays: [overlay],
    layers: [
      mapLayer_XYZ
    ],
    view: view
  });

  // apply(map, styleJson);

  new VectorLayer({
    map: map,
    source: new VectorSource({
      features: [accuracyFeature, positionFeature],
    }),
  });

  geolocation.setTracking(true);

  map.on('click', function (evt) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
          return feat;
      }
      );

      if (feature && feature.getGeometry().getType() == 'Point') {

          const coordinate = evt.coordinate;    //default projection is EPSG:3857 you may want to use ol.proj.transform

          const featureProperties = feature.getProperties();

          let htmlContent = "";

          let cases = [];

          featureProperties["cases"].forEach(function(element){
            cases.push(element["case"]);
          });

          const uniqueCases = cases.filter((v, i, a) => a.indexOf(v) === i);

          const casesText = uniqueCases.join(",");

          htmlContent += '<div class="location_title">'+featureProperties["name"]+"</div>";

          htmlContent += '<div class="location_content">最後到訪日期：'+featureProperties["last_visited"]+'</div>';
          htmlContent += '<div class="location_content">總到訪次數：'+featureProperties["cases"].length+'</div>';
          htmlContent += '<div class="location_content">總到訪個案：'+uniqueCases.length+'</div>';
          htmlContent += '<div class="location_content">個案：'+casesText+'</div>';

          content.innerHTML = htmlContent;
          overlay.setPosition(coordinate);
      }
      else {
          overlay.setPosition(undefined);
      }
  });

  map.on('pointermove', function (evt) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
          return feat;
        }
      );

      if (feature) {
          evt.map.getTargetElement().style.cursor = 'pointer';
      }
      else {
          evt.map.getTargetElement().style.cursor = '';
      }
  });
});
